.select-wrapper {
    overflow: hidden;
    position: relative;
    margin-top: 0;
    max-width: 100%;
    margin-bottom: $spacing1;

    + .button-secondary {
        margin-top: 48px;
    }

    + .button-submit {
        margin-top: 48px;
    }

    select {
        display: block;
        width: 100%;
        background: $color-grey-light;
        border: 2px solid transparent;
        border-radius: 0;
        padding: 8px 65px 8px 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        color: $color-black;
        height: 40px;

        option {
            color: $color-black;
        }

        &:active,
        &:focus {
            outline: none;
        }

        &.disabled,
        &:disabled {
            color: $color-grey-dark;
        }
    }

    select::-ms-expand {
        display: none;
    }

    &:after {
        content: "▾";
        position: absolute;
        bottom: 14px;
        right: 15px;
        border: none;
        color: $color-black;
        text-align: center;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        pointer-events: none;
        font-size: 26px;
        width: 10px;
        height: 19px;
    }

    &:hover select:not(:disabled):not(.disabled) {
        border-color: $color-blue-mid;
    }

    &.hidden {
        opacity: 0;
    }
}

@media screen and (min-width: $breakpoint-m-width) {
    .select-wrapper {
        select {
            padding-left: 20px;
        }

        &:after {
            width: 50px;
            height: 33px;
            right: 0;
            bottom: 0;
        }
    }
}
