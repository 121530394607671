/**
 * Here we mainly set the different page backgrounds, both for desktop & mobile.
 * Note that as "background-attachement: fixed" is not supported on mobile devices,
 * we have to apply a fixed pseudo-container here (:before) which is hidden on desktop
 */
.page {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: (16px + 50px) 16px $spacing8 16px;

    &--auth {
        padding-bottom: $spacing8 * 0.5;
    }

    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100%;
    }

    background-color: $color-white;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100%;
    background-attachment: fixed;

    // @media screen and (max-width: $breakpoint-l-width) {
    //     background: transparent !important;
    // }

    &:not(.page--home) > * {
        max-width: $desktop-page-content-max-width;

        @media screen and (min-width: $breakpoint-m-width) and (max-width: 1190px) {
            // On tablet: Keep inner horizontal spacing
            padding-right: 20px;
        }
    }

    &--home,
    &--home:before {
        background-image: url('../../images/home-bg-mobile.jpg');
        background-image: image-set(
            url('../../images/home-bg-mobile.webp') type('image/webp'),
            url('../../images/home-bg-mobile.jpg') type('image/jpeg')
        );
        background-attachment: initial;
    }
    &--faq,
    &--faq:before {
        background-image: url('../../images/fog-bg-faq-mobile.png');
        background-image: image-set(
            url('../../images/fog-bg-faq-mobile.webp') type('image/webp'),
            url('../../images/fog-bg-faq-mobile.png') type('image/png')
        );
    }

    &--blog,
    &--blog:before {
        background-image: url('../../images/fog-bg-blog-mobile.png');
        background-image: image-set(
            url('../../images/fog-bg-blog-mobile.webp') type('image/webp'),
            url('../../images/fog-bg-blog-mobile.png') type('image/png')
        );
    }

    &--chat,
    &--chat:before {
        background-image: url('../../images/fog-bg-chat-mobile.png');
        background-image: image-set(
            url('../../images/fog-bg-chat-mobile.webp') type('image/webp'),
            url('../../images/fog-bg-chat-mobile.png') type('image/png')
        );
    }

    &--profile,
    &--profile:before {
        background-image: url('../../images/fog-bg-dashboard-mobile.png');
        background-image: image-set(
            url('../../images/fog-bg-dashboard-mobile.webp') type('image/webp'),
            url('../../images/fog-bg-dashboard-mobile.png') type('image/png')
        );
    }

    @media screen and (max-width: $breakpoint-m-width) {
        &.page--header-green {
            padding-top: 16px;
            border-top: 50px solid $color-green;
        }
    }
}

.page-header {
    padding: 15px;
}

.inner-html {
    img {
        // Override any dimensions-attributes of
        // via WYSIWYG uploaded images
        max-width: 100%;
        height: auto !important;
    }
}

.full-width {
    // Unset padding of outer .page
    margin-right: -16px;
    margin-left: -16px;
    padding-left: 32px;
    padding-right: 32px;

    &--search {
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;

        @media screen and (max-width: $breakpoint-m-width) {
            margin-left: -16px;
        }
    }
}

.page--content-page {
    p {
        margin-bottom: $spacing1;
    }
}

@media screen and (min-width: $breakpoint-m-width) {
    .page {
        &--home,
        &--home:before {
            background-image: url('../../images/home-bg.jpg');
            background-image: image-set(
                url('../../images/home-bg.webp') type('image/webp'),
                url('../../images/home-bg.jpg') type('image/jpeg')
            );
        }
        &--blog,
        &--blog:before {
            background-image: url('../../images/fog-bg-blog.png');
            background-image: image-set(
                url('../../images/fog-bg-blog.webp') type('image/webp'),
                url('../../images/fog-bg-blog.png') type('image/png')
            );
        }
        &--chat,
        &--chat:before {
            background-image: url('../../images/fog-bg-chat.png');
            background-image: image-set(
                url('../../images/fog-bg-chat.webp') type('image/webp'),
                url('../../images/fog-bg-chat.png') type('image/png')
            );
        }
        &--faq,
        &--faq:before {
            background-image: url('../../images/fog-bg-faq.png');
            background-image: image-set(
                url('../../images/fog-bg-faq.webp') type('image/webp'),
                url('../../images/fog-bg-faq.png') type('image/png')
            );
        }
        &--profile,
        &--profile:before {
            background-image: url('../../images/fog-bg-dashboard.png');
            background-image: image-set(
                url('../../images/fog-bg-dashboard.webp') type('image/webp'),
                url('../../images/fog-bg-dashboard.png') type('image/png')
            );
        }
    }
}
