.page--profile {
    .label-nickname {
        margin-top: 25px;
        margin-bottom: 12px;
    }

    &__numbers {
        margin: 43px 0;

        a + .h3 {
            margin-top: 12px;
            line-height: 27px;
        }

        .link-blue-plain {
            padding-bottom: 2px;
            line-height: 28px;
        }

        .h-p {
            padding: 0 8px;
            font-weight: normal;
        }

        p,
        h2 {
            margin-bottom: 6px;
        }

        p > a {
            font-size: 17px;
        }
    }
}
