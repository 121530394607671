.blog-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
    background-color: $color-white;
    position: relative;
    flex-flow: wrap;

    + .blog-card {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    .blog-card-img {
        border-radius: 10px 10px 0 0;
        max-width: 100%;
        background-color: $color-yellow;
    }

    .blog-card-footer {
        border-top: 2px solid $color-blue-mid;
        align-self: flex-end;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 30px);
        margin: 0 auto;
        margin-top: 11px;
        padding: 12px 0;

        .meta-text {
            margin: 0;
        }
    }

    .blog-card-content {
        background-color: $color-white;
        padding: 15px 15px 12px 15px;

        h1,
        .h1 {
            padding-bottom: 10px;
            margin: 0;
        }

        p {
            margin-bottom: 23px;
        }

        .border-bottom {
            border-bottom: 2px solid $color-blue-mid;
            margin-bottom: 11px;
        }

        .blog-card-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .meta-text {
            margin: 0;
        }

        .link-icon-blue {
            margin: 0;
        }
    }
}

.blog-single {
    display: flex;
    flex-direction: column;
    position: relative;

    + .blog-single {
        margin-top: 16px;
    }

    .blog-single-img {
        max-width: 100%;
        z-index: 1;
        margin-bottom: -30px;
    }

    .blog-single-content {
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        width: calc(100% - 30px);
        z-index: 2;
        margin-left: 15px;
        padding: 15px 15px 12px 15px;

        h1,
        .h1 {
            padding-bottom: 11px;
            margin: 0;
        }

        p {
            margin-bottom: 23px;
        }

        .border-bottom {
            border-bottom: 2px solid $color-blue-mid;
            margin-top: 11px;
            margin-bottom: 11px;
        }

        .meta-text {
            margin: 0;
        }

        iframe {
            max-width: 100%;
        }

        img {
            // Override any width/height attribute added
            // by CKEditor
            max-width: 100%;
            width: 100% !important;
            height: auto !important;
        }
    }
}
