// Badge mainly used by unread-notification.view
$badge-radius: 18px;

.badge {
    display: block;
    float: right;
    width: $badge-radius;
    height: $badge-radius;
    line-height: $badge-radius;
    font-size: 12px;
    text-align: center;
    background: $color-white;
    border-radius: 99px;
    color: $color-blue-mid;
    @include box-shadow();

    &--invert {
        background: $color-blue-mid;
        color: $color-white;
    }

    &--private {
        background: #ce190c;
        color: $color-white;
    }
}

.titlebar__toggle-menu .badge {
    position: absolute;
    margin-top: -12px;
    margin-left: 4px;
}

.page--profile .flex-container-list .badge {
    // @see _list.scss:6
    position: absolute;
    bottom: 30%;
    right: 24px;
    z-index: 98;
}

.chat-card-user .badge {
    position: absolute;
    right: -12px;
    margin-top: -3px;
}
