@mixin user-badge() {
    display: inline-block;
    content: "Du";
    color: $color-white;
    font-size: 14px;
    letter-spacing: $letter-spacing;
    font-weight: bold;
}

.chat-list-topic {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    padding: 15px 31px 15px 31px;

    + .chat-list-topic {
        margin-top: 8px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    + .headline-section {
        margin-top: 48px;
    }

    h2,
    .h2 {
        padding-bottom: 11px;
        margin-bottom: 0;
        margin-right: 25px;
    }

    .label-hashtag-link {
        margin: 0;
    }

    i {
        position: absolute;
        right: 31px;
        bottom: 35%;
        font-style: normal;

        &:after {
            content: "→";
            display: inline;
            color: $color-blue-mid;
            font-weight: bold;
            font-style: normal;
        }
    }
}

.border-bottom {
    border-bottom: 2px solid $color-blue-mid;
    margin-bottom: 11px;
}

/** 
 * Fixes [#4975] "Warning: validateDOMNesting(...): <a> cannot appear as a descendant of <a>." 
 * Instead of wrapping a container in <a>, only the title is wrapped in <a> which invisibly
 * covers the whole container (implemented e.g. in chat-thread-list-item.view.tsx)
*/
.card-pseudo-link {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }
}

.chat-card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    border-radius: 10px 10px 10px 10px;
    padding: 15px 15px 12px 15px;

    a:not(.card-pseudo-link) {
        position: relative;
        z-index: 10;
    }

    + .chat-card,
    .chat-card + .chat-card-user,
    .chat-card + .chat-card-user-response {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .meta-text:not(.meta-edit-text) {
        margin: 0;
    }

    p {
        margin-bottom: 15px;
        white-space: pre-line;
    }

    .label-hashtag-link {
        margin: 0;
    }

    .link-icon-blue {
        margin: 0;
    }

    &--group {
        &.chat-article-user {
            .border-bottom {
                margin-left: 15px;
            }
        }

        &__footer {
            .group-info {
                text-align: center;
                margin-top: 10px;
                color: $color-grey-dark;
            }
        }
    }

    &--unread {
        border-color: $color-green !important;
    }

    &-group-indicator {
        @include border-radius(10px);
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        background-color: $color-grey-light;
        padding: 8px 15px;
        margin-top: 10px;

        @media screen and (max-width: 420px) {
            flex-direction: column;
            gap: 2px;
        }

        > div {
            display: flex;
            gap: 8px;

            img {
                width: 12px;
            }

            span {
                color: $color-black;
            }
        }
    }
}

.chat-card-user {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-green-light;
    border-radius: 10px 10px 10px 10px;
    border: 2px solid $color-white;
    padding: 15px 15px 12px 15px;
    margin-top: 16px;

    + .chat-card,
    .chat-card-user + .chat-card-user,
    .chat-card-user + .chat-card-user-response {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .user {
        position: relative;
        background-color: $color-green;
        width: 50px;
        height: 20px;
        margin-left: -15px;
        border-radius: 0 5px 5px 0;
        text-align: center;

        &:after {
            @include user-badge();
        }
    }

    .meta-text {
        margin: 0;
        padding-top: 0;
    }

    p,
    h2,
    .h2 {
        margin-bottom: 15px;
        margin-top: 8px;
    }

    h2,
    .h2 {
        margin-top: 15px;
    }

    .border-bottom {
        border-bottom: 2px solid $color-blue-mid;
        margin-bottom: 11px;
    }

    .link-icon-blue {
        margin: 0;
        padding-top: 0;
    }
}

.chat-card-user-response {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    border-radius: 10px 10px 10px 10px;
    padding-left: 15px;
    padding-right: 16px;
    padding-top: 15px;
    padding-bottom: 12px;

    + .chat-card,
    .chat-card-user-response + .chat-card-user,
    .chat-card-user-response + .chat-card-user-response {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .meta-text {
        padding-bottom: 10px;
        margin: 0;
    }

    p {
        padding-bottom: 20px;
        margin: 0;
    }

    .chat-card-user {
        padding-right: 15px;
        padding-bottom: 24px;
        border: none;

        .user {
            background-color: $color-green;
            width: 50px;
            height: 20px;
            border-radius: 0 5px 5px 0;
            text-align: center;

            &:after {
                @include user-badge();
            }
        }

        .meta-text {
            margin-bottom: 0;
        }

        p {
            padding-left: 15px;
            margin: 0;
            padding-bottom: 0;
        }
    }

    .border-bottom {
        border-bottom: 2px solid $color-blue-mid;
        margin-bottom: 11px;
        margin-top: 17px;
    }

    .label-hashtag-link {
        margin-bottom: 0;
        padding: 0;
    }

    .link-icon-blue {
        margin-bottom: 0;
        padding: 0;
    }
}

.chat-article {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    border-radius: 10px 10px 10px 10px;
    padding-left: 15px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-top: 15px;

    + .chat-article,
    .chat-article + .chat-article-user {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .meta-text {
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    h2,
    .h2 {
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    p {
        padding-bottom: 15px;
    }

    .meta-text {
        padding-bottom: 19px;
    }

    .border-bottom {
        border-bottom: 2px solid $color-blue-mid;
    }

    .label-hashtag-link {
        padding-top: 11px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.chat-article-user {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px 10px 10px 10px;
    padding-right: 16px;
    padding-bottom: 17px;
    padding-top: 15px;
    background-color: $color-green-light;
    border: 2px solid $color-white;

    + .chat-article,
    .chat-article-user + .chat-article-user {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .user {
        background-color: $color-green;
        width: 50px;
        height: 20px;
        border-radius: 0 5px 5px 0;
        text-align: center;
        margin-bottom: 15px;

        &:after {
            @include user-badge();
        }
    }

    .meta-text {
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    .chat-card--group__footer {
        margin-left: 15px;

        p {
            padding: 0;
        }
    }

    h2,
    .h2 {
        padding-bottom: 15px;
        margin-bottom: 0;
        padding-left: 15px;
    }

    p {
        padding-bottom: 15px;
        padding-left: 15px;
        white-space: pre-line;
    }

    .meta-text {
        padding-left: 15px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.chat-reaction {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    border-radius: 10px 10px 10px 10px;
    padding-left: 15px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-top: 15px;

    + .chat-reaction,
    .chat-reaction + .chat-reaction-user-reviewed,
    .chat-reaction + .chat-reaction-user {
        margin-top: 16px;
    }

    + button,
    .button {
        margin-top: 48px;
    }

    + .chat-separator,
    .chat-reaction + .chat-answer {
        margin-top: 48px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .meta-text {
        padding-bottom: 12px;
        margin-bottom: 0;
    }

    p {
        padding-bottom: 15px;
    }

    .border-bottom {
        border-bottom: 2px solid $color-blue-mid;
    }

    .label-hashtag-link {
        margin-bottom: 0;
        padding-top: 11px;
        padding-bottom: 0;
    }
}

.chat-reaction-user-reviewed {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px 10px 10px 10px;
    padding-right: 16px;
    padding-bottom: 17px;
    padding-top: 15px;
    background-color: $color-green-light;
    border: 2px solid $color-white;

    + .chat-reaction,
    .chat-reaction-user-reviewed + .chat-reaction-user-reviewed,
    .chat-reaction-user-reviewed + .chat-reaction-user {
        margin-top: 16px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .user {
        background-color: $color-green;
        width: 50px;
        height: 20px;
        border-radius: 0px 5px 5px 0px;
        text-align: center;
        margin-bottom: 15px;

        &:after {
            @include user-badge();
        }
    }

    .meta-text {
        padding-bottom: 15px;
        margin-bottom: 0px;
    }

    p {
        padding-bottom: 15px;
        padding-left: 15px;
    }

    .meta-text {
        padding-left: 15px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}

.chat-reaction-user {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px 10px 10px 10px;
    padding-right: 16px;
    padding-bottom: 17px;
    padding-top: 15px;
    background-color: $color-green-light;
    border: 2px solid $color-white;

    + .chat-reaction,
    .chat-reaction-user + .chat-reaction-user,
    .chat-reaction-user + .chat-reaction-user-reviewed {
        margin-top: 16px;
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .user {
        background-color: $color-green;
        width: 50px;
        height: 20px;
        border-radius: 0px 5px 5px 0px;
        text-align: center;
        margin-bottom: 15px;

        &:after {
            @include user-badge();
        }
    }

    .meta-text {
        padding-bottom: 15px;
        margin-bottom: 0px;
    }

    p {
        padding-bottom: 15px;
        padding-left: 15px;
    }
}

.chat-separator {
    display: flex;
    flex-direction: row;

    + .chat-reaction {
        margin-top: 48px;
    }

    &:before,
    &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid $color-white;
        margin: auto;
    }

    &:before {
        margin-right: 12px;
    }

    &:after {
        margin-left: 12px;
    }

    .text-white {
        text-align: center;
        margin-bottom: 0px;
        font-size: $meta-text-font-size;
        line-height: $label-line-height-white;
        letter-spacing: $letter-spacing;
    }
}

.chat-answer {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    border-radius: 10px 10px 10px 10px;
    border: 2px solid $color-white;
    padding-bottom: 8px;

    textarea:focus {
        border: none;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="date"],
    textarea {
        background-color: $color-green-light;
        border-radius: 10px 10px 10px 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 30px;
        min-height: 99px;
        display: inline-block;
        margin-top: 0px;

        textarea:focus {
            border: none;
        }
    }

    .chat-card-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    .button-close-white {
        margin-left: 15px;
        margin-bottom: 0px;
    }

    .button-submit {
        margin-right: 15px;
        margin-bottom: 0px;
    }
}
