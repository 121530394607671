.faq-card-topic {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: relative;
    padding: 15px 15px 15px 15px;

    + .faq-card-topic {
        margin-top: 8px;
    }

    + .headline-section {
        margin-top: 48px;
    }

    &:after {
        content: "";
        position: absolute;
        background-color: $color-white;
        opacity: 0.4;
        top: 10px;
        left: 100%;
        width: 10px;
        height: calc(100% - 20px);
    }

    .h1 {
        margin-bottom: 8px;
    }

    .faq-card-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .meta-text {
        margin-bottom: 0;
    }
}

.faq-card-question {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: relative;
    padding: 15px;

    + .faq-card-question {
        margin-top: 8px;
    }

    + button, .button {
        margin-top: 48px;
    }

    h2, .h2 {
        margin: 0;

        &:after {
            content: "→";
            position: absolute;
            margin-left: 10px;
            font-size: $h2-font-size;
            color: $color-blue-mid;
            font-weight: bold;
        }
    }
}

.faq-card-answer {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: relative;
    padding: 15px 15px 21px 15px;

    + .faq-card-answer {
        margin-top: 8px;
    }

    h2, .h2 {
        padding-bottom: 15px;
        margin: 0px;

    }

    .border-bottom {

        border-bottom: 2px solid $color-blue-mid;
        margin-bottom: 15px;
    }

    p {

    }
}

