$item-distance: 22px;

@media screen and (max-width: $breakpoint-m-width) {
    body[data-page='auth'],
    body[data-page='selbsttest'] {
        .component--chatbot-widget {
            display: none;
        }
    }
}

body.chatbot-widget-active {
    position: fixed;
    overflow: hidden;
    // Experimental feature; disables pull-to-refresh on Android
    // @see https://developers.google.com/web/updates/2017/11/overscroll-behavior
    overscroll-behavior-y: contain;
}

.component--chatbot {
    max-width: $desktop-page-content-max-width;
    min-height: 100%;
    transition: 0.5s all;
    scrollbar-width: none; // Hide scrollbar for FF
    overflow-y: auto;
    // Small "hack": overflow-y option hides box-shadow of children
    // -> recover by adding pseudo space
    padding-right: 25px;
    margin-right: -25px;
    padding-left: 25px;
    margin-left: -25px;
    //padding-bottom: 25px;
    margin-bottom: -25px;

    &::-webkit-scrollbar {
        display: none; // Hide scrollbar for Chrome
    }

    &[data-context='1'] {
        .component--chatbot-dialogue-list-item:first-child {
            padding-top: 20px;
        }
    }

    &[data-context='2'] {
        // Self-test
        .component--chatbot-dialogue-list-item:first-child .list-item-content {
            font-weight: bold;
        }
    }

    /**
     * Alternative chatbot styling, used on Selbsttest- & Auth-pages.
     * Not whole outer chatbot is scrollable, but inner dialogue-list.
     * Advantage: Height can be calculated via CSS (not JS as in chatbot.view)
     * and input-field is sticky on bottom
     */
    &.is-subpage {
        position: relative;
        overflow: visible;
        padding: 0;
        margin: 0;
        margin-right: -25px;

        .component--chatbot-dialogue-list {
            overflow-y: scroll;
            scrollbar-width: none;
            overflow-x: visible;
            padding-left: 50px;
            margin-left: 0;
            padding-right: 25px;
            padding-bottom: 25px;
            height: calc(100vh - #{$header-height + 70px});

            @media screen and (min-width: $breakpoint-m-width) {
                height: calc(100vh - #{$header-height + 180px});
            }

            &::-webkit-scrollbar {
                display: none; // Hide scrollbar for Chrome
            }

            &-item:nth-last-child(2) {
                margin-bottom: 0;
            }
        }

        .component--chatbot-text-input {
            margin-left: 0;
            bottom: -50px;
            width: calc(100% - 25px);
        }
    }

    &-dialogue-list {
        // @see https://philipwalton.github.io/solved-by-flexbox/demos/vertical-centering/
        position: relative;
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        min-height: inherit;

        &-item {
            .list-item-content {
                padding: 9px 20px;
                max-width: max-content;
                opacity: 0;
                white-space: pre-line;
                @include animation-chatbot();

                &[data-dialogue-type='left'] {
                    background-color: $color-white;
                    border-radius: 0 10px 10px 10px;
                    margin-top: 8px;
                    margin-bottom: $item-distance;
                    @include box-shadow();
                    position: relative;

                    &:before {
                        content: '';
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        margin-top: -15px;
                        margin-left: -82px;
                        background: transparent url('../../images/chatbot.svg') no-repeat top left;
                        background-size: 62px;
                    }

                    + .answer-options {
                        margin-top: -15px;
                    }
                }

                &[data-dialogue-type='center'] {
                    background-color: $color-grey-dark;
                    border-radius: 10px 0 10px 10px;
                    color: $color-white;
                    margin: 4px 0 $item-distance auto;
                }

                &[data-dialogue-type='right'] {
                    background-color: $color-blue-mid;
                    border-radius: $radius;
                    font-weight: bold;
                    color: $color-white;
                    margin-top: 4px;
                    cursor: pointer;
                }

                &.is-fc {
                    // Let inner component style it own
                    overflow: hidden;
                    padding: 0;
                }

                .chatbot-counter-item {
                    color: $color-white;
                    font-weight: bold;

                    .meta-text {
                        display: block;
                        float: right;
                        width: 44px; // Avoid "flickering"
                        color: $color-white;
                        padding-left: 8px;
                        line-height: 22px;
                        word-break: keep-all;
                    }
                }
            }

            .answer-options {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }

            &:nth-last-child(2) {
                margin-bottom: 50px;
            }
        }

        &-answer-option {
            margin-left: 10px;
            padding: 9px 23px;
            max-width: max-content;
            background-color: $color-blue-mid;
            border-radius: $radius;
            font-weight: bold;
            color: $color-white;
            margin-top: 4px;
            cursor: pointer;
            width: auto;
            margin-bottom: $item-distance;

            &.has-answer {
                background-color: $color-grey-dark;
                color: $color-white;
                font-weight: normal;
                border-radius: 20px 0 20px 20px;
            }
        }
    }
}

.component--chatbot-widget {
    &-switch {
        position: fixed;
        bottom: 5px;
        right: 5px;

        .chatbot-widget-tooltip {
            position: fixed;
            right: 22px;
            bottom: 30px;
            max-width: 267px;

            .list-item-content {
                border-radius: 10px;

                &:before {
                    display: none;
                }

                $tooltip-arrow-size: 9px;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: $tooltip-arrow-size solid transparent;
                    border-right: $tooltip-arrow-size solid transparent;
                    border-top: $tooltip-arrow-size solid $color-white;
                    bottom: -$tooltip-arrow-size;
                    right: 20px;
                }
            }
        }

        img {
            cursor: pointer;
            width: 100%;
            @include animation-chatbot();
        }
    }

    &-overlay {
        padding: 32px 16px 6px 16px;
        transition: 0.01s padding-top;

        &[data-drag-status] {
            .button-close-blue {
                opacity: 0;
            }
            .component--chatbot-widget-overlay__inner {
                opacity: 0.8;
            }

            .component--chatbot {
                opacity: 0.7;
                filter: blur(1px);
            }
        }

        // Y-Axis scroll steps animation
        &[data-drag-status='1'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.85;
            }

            .component--chatbot {
                opacity: 0.7;
                filter: blur(1px);
            }
        }

        &[data-drag-status='2'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.75;
            }

            .component--chatbot {
                opacity: 0.45;
                filter: blur(2px);
            }
        }

        &[data-drag-status='3'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.65;
            }

            .component--chatbot {
                opacity: 0.2;
                filter: blur(3px);
            }
        }

        &[data-drag-status='4'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.45;
            }

            .component--chatbot {
                opacity: 0;
            }
        }

        &[data-drag-status='5'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.35;
            }

            .component--chatbot {
                opacity: 0;
            }
        }

        &[data-drag-status='6'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.2;
            }

            .component--chatbot {
                opacity: 0;
            }
        }

        &[data-drag-status='7'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0.05;
            }

            .component--chatbot {
                opacity: 0;
            }
        }

        &[data-drag-status='8'],
        &[data-drag-status='9'],
        &[data-drag-status='10'] {
            .component--chatbot-widget-overlay__inner {
                opacity: 0;
            }

            .component--chatbot {
                opacity: 0;
            }
        }

        .button-close-blue {
            position: absolute;
            right: 10px;
            top: 20px;
            float: right;
            z-index: 999;
            transition: opacity 0.4s;
        }

        $drag-area-height: 52px;

        &__drag-area {
            position: relative;
            z-index: 999;
            width: 35%;
            min-width: 150px;
            margin: 0 auto;
            height: $drag-area-height;
            margin-bottom: -$drag-area-height;
            color: $color-grey-dark;
            text-align: center;
            letter-spacing: -2px;
            cursor: row-resize;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &__inner {
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.75) 0%,
                $color-white 50%,
                $color-white 100%
            );
            padding: 31px 0 10px 0;
            border-radius: 10px;
            height: 100%;
            transition: 0.4s opacity;
            overflow: hidden;

            .component--chatbot {
                width: 100%;
                max-width: 100%;
                margin: 0;
                padding: 0 15px !important;
            }

            .chat-input {
                bottom: 10px;
            }
        }
    }
}
