.privacy-embed-wrapper {
    width: 100%;
    padding: 10px;

    .privacy-embed-source {
        display: none;
    }

    .privacy-embed-content {
        h2,
        p {
            margin-bottom: 15px;
        }
        button {
            margin-bottom: 0 !important;
            margin-top: 15px !important;
        }
    }
}
