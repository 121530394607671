// This media-query targets a very specific tablet-like viewport (!== $breakpoint-m-width)
@media screen and (min-width: 1100px) and (max-width: 1400px) {
    .page--home {
        .image-teaser-list .image-teaser {
            h3 {
                font-size: 27px !important;
                line-height: 35px !important;
            }
            .link-white {
                margin-right: 15px;
            }
        }
    }
}
