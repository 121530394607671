input:active,
input:focus,
button.button-send:active,
button.button-send:focus {
    outline: none;
}

input[type=text]:not(#chatbot-text-input):not([name="q"]),
input[type=password]:not(#chatbot-text-input),
input[type=number],
input[type=tel],
input[type=email],
input[type=date],
input[type=file],
textarea {
    + button, .button {
        margin-top: 48px;
    }

    + .button-secondary {
        margin-top: 48px;
    }

    border: 2px solid transparent;
    color: $color-black;
    background: $color-grey-light;

    padding: 8px 10px;
    line-height: 22px;
    display: block;
    width: 100%;
    height: 40px;
    font-size: 16px;
    margin-top: 0;

    &.input--active, &:active, &:focus {
        border-color: $color-blue-mid;
        outline: none;
    }

    &[disabled] {
        color: grey;
        pointer-events: none;
    }
}

textarea {
    height: 200px;
}

.input-wrapper {
    margin-bottom: $spacing1;
}

.input-wrapper--file {
    // WTF Forms approach:
    // Hide actual file input, re-style via span.file-input
    // On focus add border 2px solid blue?
    margin-top: 20px;

    label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 100%;
    }

    input[type=file] {
        min-width: 14rem;
        margin: 0;
        opacity: 0;
    }

    .file-input {
        position: absolute;
        top: 30px;
        right: 0;
        left: 0;
        z-index: 5;
        height: 40px;
        padding: 0 185px 0 10px;
        line-height: 16px;
        color: $color-grey-dark;
        background-color: $color-grey-light;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;

        &:before {
            position: absolute;
            top: -.075rem;
            right: -.075rem;
            bottom: -.075rem;
            z-index: 6;
            display: block;
            content: "Datei auswählen";
            height: 40px;
            padding: .5rem 1rem;
            line-height: 1.5;
            color: $color-white;
            font-weight: bold;
            background-color: $color-blue-mid;
            border-radius: 10px;
        }

        &:after {
            content: "Datei";
            line-height: 40px;
        }

        &.selected:after {
            content: attr(data-file-name);
        }
    }
}

@media screen and (min-width: $breakpoint-m-width) {
    input[type=text]:not(#chatbot-text-input):not([name="q"]),
    input[type=password]:not(#chatbot-text-input),
    input[type=number],
    input[type=tel],
    input[type=email],
    input[type=date],
    textarea {
        padding: $spacing1;
    }
}
