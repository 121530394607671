*,
div,
:after,
:before {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    vertical-align: top;
}

body {
    overflow-x: hidden;
    position: relative;
    background-color: $color-white;
}

figure,
img,
picture {
    position: relative;
    line-height: 0;
    width: 100%;
    height: auto;
}

::selection {
    background: $color-blue-dark;
    color: $color-white;
}

::-moz-selection {
    background: $color-blue-dark;
    color: $color-white;
}
