.invisible, .hidden {
    display: none;
}

.text-center {
    display: block;
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-right {
    display: block;
    text-align: right;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.text-linethrough {
    text-decoration: line-through;
}

.bg-image {
    background-color: transparent;
    background-size: cover;
    background-position: center center;
}

.no-hyphens {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
