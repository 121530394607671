// background colors

.bg-white, .bg-white:before {
  background-color: $color-white;
}

.bg-black, .bg-black:before {
  background-color: $color-black;
}

.bg-blue-mid, .bg-blue-mid:before {
  background-color: $color-blue-mid;
}

.bg-blue-dark, .bg-blue-dark:before {
  background-color: $color-blue-dark;
}

.bg-pink, .bg-pink:before {
  background-color: $color-pink;
}

.bg-orange, .bg-orange:before {
  background-color: $color-orange !important;
}

.bg-yellow, .bg-yellow:before {
  background-color: $color-yellow !important;
}

.bg-green, .bg-green:before {
  background-color: $color-green !important;
}

.bg-grey-light, .bg-grey-light:before {
  background-color: $color-grey-light;
}

.bg-grey-dark, .bg-grey-dark:before {
  background-color: $color-grey-dark;
}


// text colors

.text-white, .text-white *:not(a) {
  color: $color-white;
}

.text-black, .text-black *:not(a) {
  color: $color-black;
}

.text-blue-mid {
    color: $color-blue-mid !important;
}
