.captcha-field {
    @include flexbox;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;

    img {
        width: calc(100% - 90px);

        @media screen and (min-width: $breakpoint-m-width) {
            width: calc(100% - 130px);
        }
    }

    &.chatbot-captcha-item {
        img {
            width: calc(100% - 80px);

            @media screen and (min-width: $breakpoint-m-width) {
                width: calc(100% - 120px);
            }
        }
    }

    button {
        min-width: initial;
        width: 80px;
        position: relative;
        margin-bottom: 0;
        border-radius: 0;
        background-color: $color-grey-light;
        border: 2px solid transparent;

        @media screen and (min-width: $breakpoint-m-width) {
            width: 120px;
        }

        &:after {
            content: '';
            position: absolute;
            background: url('../../images/icon-captcha-refresh.svg') no-repeat center center;
            background-size: 26px auto;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            @media screen and (min-width: $breakpoint-m-width) {
                background-size: 36px auto;
            }
        }

        &:hover {
            border-color: $color-blue-mid;
        }
    }
}

.captcha-wrapper .captcha-field {
    margin-bottom: 10px;
}

.chatbot-captcha-item {
    button {
        min-width: auto;
        margin-left: 0;
    }
}
