.component--chat-group-membership-form {
    &__list {
        background-color: $color-grey-light;
        padding: 15px 10px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        &__actions {
            position: absolute;
            right: 10px;
            z-index: 88;
            display: flex;
            gap: 10px;
        }

        li {
            position: relative;
            display: flex;
            align-content: center;
            align-items: center;

            &:before {
                content: '';
                width: 80px;
                height: 57px;
                background: transparent url('../../images/chatbot_blue.svg') no-repeat top left;
                background-size: 100%;
            }

            &:not(:first-child) {
                border-top: 2px solid $color-white;
                padding-top: 10px;
            }

            .btn-delete,
            .btn-accept {
                width: 40px;
                height: 40px;
                color: #fff;
                text-align: center;
                font-size: 1.5rem;
                cursor: pointer;
                transition: 0.2s background-color;
                padding-top: 7px;
            }

            .btn-delete {
                background-color: rgba(0, 0, 0, 0.5);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                }

                &:after {
                    content: '\D7';
                    display: inline;
                    font-size: 2rem;
                }
            }

            .btn-accept {
                background-color: $color-blue-mid;
                display: flex;
                align-items: center;
                justify-content: center;

                &:after {
                    content: '';
                    display: block;
                    background: transparent url('../../images/icon-confirm.svg') no-repeat top left;
                    width: 20px;
                    height: 20px;
                    position: relative;
                }
            }
        }
    }
}
