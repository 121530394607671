.chat-input {
    position: absolute;
    bottom: 0;
    margin-left: -50px;
    width: calc(100% + 50px);

    input {
        border: 2px solid $color-grey-light;
        border-radius: $radius;
        height: 40px;
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        background-color: $color-white;
        color: $color-black;
        opacity: 0.7;
        padding-left: 15px;
        padding-right: 70px;

        &:focus, &:active {
            opacity: 1.0;
        }
    }

    .button-send {
        position: absolute;
        right: 0;
        top: 0;
        margin-bottom: 0;
    }
}
