div,
p,
span,
em,
i,
li,
th,
td,
address,
a,
input,
textarea,
label,
select,
button,
.button,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.strong,
.strong *,
strong * {
    font-family: "Graphit", -apple-system, Arial, sans-serif;
    color: $color-black;
}

div,
p,
em,
i,
li,
th,
td,
address,
a,
input,
textarea,
label,
select,
button,
.button {
    font-size: $text-font-size;
    font-weight: normal;
    line-height: $text-line-height;
    word-break: break-word;
}

p {
    & + button,
    & + .button {
        margin-top: 48px;
    }
}

.strong {
    font-weight: bold;
}

.title-white {
    font-size: $title-font-size;
    color: $color-white;
    font-weight: bold;
    line-height: $title-line-height;
    margin-bottom: $spacing1;
}

.title-black {
    font-size: $title-font-size;
    color: $color-black;
    font-weight: bold;
    line-height: $title-line-height;
    margin-bottom: $spacing1;
}

h1,
.h1 {
    @include text-h1;
}

h2,
.h2 {
    @include text-h2;
}

h3,
.h3 {
    @include text-h3;
}

.text-white {
    font-size: $text-font-size;
    line-height: $text-line-height;
    color: $color-white;
    margin-bottom: $spacing1;

    + .headline-section,
    .text-white + .faq-card-question,
    .text-white + .blog-card,
    .text-white + .chat-card,
    .text-white + .chat-card-user,
    .text-white + .chat-card-user-response {
        margin-top: 48px;
    }
}

.meta-text {
    font-size: $meta-text-font-size;
    line-height: $meta-text-line-height;
    color: $color-grey-dark;
    margin-bottom: $spacing1;
    letter-spacing: $letter-spacing;
}

.label-hashtag-link {
    font-size: $text-font-size;
    line-height: $label-line-height;
    color: $color-blue-mid;
    margin-bottom: $spacing1;
    letter-spacing: $letter-spacing;
}

.label-section-heading {
    font-size: $text-font-size;
    line-height: $label-line-height;
    margin-bottom: $spacing1;
    letter-spacing: $letter-spacing;
}

.label-section-heading-white {
    font-size: $text-font-size;
    line-height: $label-line-height-white;
    color: $color-white;
    margin-bottom: $spacing1;
    letter-spacing: $letter-spacing;
}

.label-nickname {
    font-size: $meta-text-font-size;
    line-height: $meta-text-line-height;
    font-weight: bold;
    margin-bottom: $spacing1;
    letter-spacing: $letter-spacing;
}

.link-icon-blue {
    font-size: $h2-font-size;
    color: $color-blue-mid;
    line-height: $button-line-height;
    font-weight: bold;
}

.link-icon-white {
    font-size: $h2-font-size;
    color: $color-white;
    line-height: $button-line-height;
    font-weight: bold;
}

.link-blue,
.link-blue-plain {
    color: $color-blue-mid;
    font-size: $h2-font-size;
    line-height: $button-line-height;
    font-weight: bold;
    border-bottom: 2px solid $color-blue-mid;
    padding-bottom: 4px;
    cursor: pointer;
}

.link-white,
.link-white-plain {
    color: $color-white;
    font-size: $h2-font-size;
    line-height: $button-line-height;
    font-weight: bold;
    border-bottom: 2px solid $color-white;
    padding-bottom: 4px;
}

.link-blue:after,
.link-white:after {
    content: "→";
    font-weight: bold;
    font-style: normal;
    display: inline;
    margin-left: 5px;
}

.headline-section {
    background-color: transparent;
    color: $color-white;
    font-size: $meta-text-font-size;
    line-height: $label-line-height-white;
    padding-bottom: 16px;
    margin-left: 15px;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
}

.letter-spacing {
    letter-spacing: $letter-spacing;
}

a {
    @include transition("color");
}

a,
a:active,
a:focus,
a:visited,
a:hover {
    text-decoration: none !important;
}

p a {
    color: $color-blue-mid;
}

a.text-underline {
    text-decoration: underline !important;
}

ul {
    padding-left: 1em;
}

@media screen and (min-width: $breakpoint-m-width) {
    div,
    p,
    span,
    em,
    i,
    li,
    th,
    td,
    address,
    a,
    input,
    textarea,
    label,
    select,
    button,
    .button {
        //font-size: $text-font-size-m;
        //line-height: $text-line-height-m;
    }

    h1,
    .h1 {
        //font-size: $h1-font-size-m;
        //line-height: $h1-line-height-m;
    }

    h2,
    .h2 {
        //font-size: $h2-font-size-m;
        //line-height: $h2-line-height-m;
    }
}
