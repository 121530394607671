@use "sass:math";

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin inline-flexbox() {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.flex-wrapper {
  @include flexbox();
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.inline-flex-wrapper {
  @include inline-flexbox();
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.flex-wrapper,
.inline-flex-wrapper {
  &.flex-wrapper--center {
    justify-content: center;
    align-items: center;
  }

  &.flex-wrapper--hcenter {
    justify-content: center;
  }

  &.flex-wrapper--vcenter {
    align-items: center;
  }

  &.flex-wrapper--right {
    justify-content: flex-end;
  }

  &.flex-wrapper--stretch {
    align-items: stretch;
  }
}

.flex-square {
  flex: 1 0 0;

  &:before {
    /* The psuedo-element's padding-top percentage is based on the element's width. */
    padding-top: 100%;
    content: "";
    float: left;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.flex-col-1 {
  width: percentage(math.div(1, 12));
}

.flex-col-2 {
  width: percentage(math.div(2, 12));
}

.flex-col-3 {
  width: percentage(math.div(3, 12));
}

.flex-col-4 {
  width: calc(#{percentage(math.div(4, 12))} - 22px);
}

.flex-col-5 {
  width: percentage(math.div(5, 12));
}

.flex-col-6 {
  width: calc(#{percentage(math.div(6, 12))} - #{$spacing1});
}

.flex-col-7 {
  width: percentage(math.div(7, 12));
}

.flex-col-8 {
  width: percentage(math.div(8, 12));
}

.flex-col-9 {
  width: percentage(math.div(9, 12));
}

.flex-col-10 {
  width: percentage(math.div(10, 12));
}

.flex-col-11 {
  width: percentage(math.div(11, 12));
}

.flex-col-12 {
  width: 100%;
}

@media screen and (max-width: $breakpoint-m-width) {
  .flex-square {
    flex: 0 1 auto;
  }
  .flex-col-1,
  .flex-col-2,
  .flex-col-3,
  .flex-col-4,
  .flex-col-5,
  .flex-col-6,
  .flex-col-7,
  .flex-col-8,
  .flex-col-9,
  .flex-col-10,
  .flex-col-11 {
    width: 100%;
  }
}
