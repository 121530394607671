.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.7);
}

// Optimized for display on /notfallkoffer view (may differ when content is not of type img)
.component--modal {
    position: relative;
    z-index: 99;

    .modal-overlay {
        display: flex;
        align-items: center;

        .modal-content {
            padding: 0;
            margin: 0 auto;
            text-align: center;
            @include box-shadow();
            @include animation-chatbot();

            @media screen and (min-width: $breakpoint-l-width) {
                img {
                    max-height: 95vh;
                    max-width: 75vw;
                }
            }

            .button-close-blue {
                position: absolute;
                z-index: 999;
                right: -10px;
                margin-top: -10px;
            }

            .delete-account-modal {
                background-color: $color-white;
                text-align: left;
                padding: 30px 30px 0 30px;

                &__content {
                    padding: 10px 0;

                    .radio-wrapper {
                        height: auto;
                        padding-bottom: 10px;
                    }
                }
            }

            .form__buttons {
                @media screen and (max-width: 460px) {
                    justify-content: flex-end;
                }
            }
        }
    }
}
