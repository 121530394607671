.component--offline-notification {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $color-orange;
    text-align: center;
    padding: 15px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.29);
    z-index: 999;

    p {
        color: $color-white;
    }

    a {
        color: $color-white;
        text-decoration: underline !important;
    }
}

body[data-page=''] .component--offline-notification {
    z-index: 1000;
}
