.component--chat-post-list + .component--chat-post-add,
.component--chat-thread-list + .component--chat-thread-add-button,
.component--chat-thread-list + p,
.component--chat-topic-list + .headline-section,
.component--chat-topic-list + .button,
.component--chat-topic-list + .component--chat-thread-add-button {
    margin-top: 48px;
}

.page.page--chat-thread-new {
    background: #fff;
}

.component--chat-article-item.chat-card,
.component--chat-article-item.chat-article-user {
    margin-bottom: 16px;
}

.component--chat-post-list-item,
.component--chat-article-item {
    .meta-text {
        display: flex;

        a {
            padding-left: 10px;

            &:before {
                content: '';
                display: block;
                height: 20px;
                width: 20px;
                background: transparent url('../../images/icon-pen.svg') no-repeat center center;
                background-size: 48px;
                margin-top: -1px;
            }
        }
    }
}

.page.page--self-test,
.page.page--auth {
    @media screen and (max-width: $breakpoint-m-width) {
        padding-top: 50px; // Imitate navbar
    }

    .page-header {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.page.page--self-test .component--chatbot,
.page.page--auth .component--chatbot {
    margin-bottom: 0;
}

.component--chat-topic-list {
    margin-left: -16px;
    margin-right: -16px;

    @media screen and (min-width: $breakpoint-l-width) {
        margin-left: 0;
        margin-right: 0;
    }
}

.page--chat-thread-detail {
    .component--chat-post-list .chat-card {
        margin-right: 25px;

        &.chat-card-user {
            margin-right: 0;
            margin-left: 25px;
        }
    }

    .component--chatbot-dialogue-list-item:nth-last-child(2) {
        margin-bottom: 0;
    }

    .chat-input {
        width: calc(100% - 64px);
        margin-left: 0;
        bottom: 32px;

        @media screen and (min-width: $breakpoint-l-width) {
            max-width: $desktop-page-content-max-width - 32px;
        }
    }

    &__toggle-visibility {
        padding-top: 30px;

        .button-hollow {
            margin: 0 auto;
        }

        .chat-separator {
            margin-top: 35px;
            margin-bottom: 50px;
        }
    }
}

.chat-card.is-mod {
    .label-nickname,
    .label-mod {
        color: $color-blue-mid !important;
    }
}

.component--chat-post-add-form textarea {
    resize: vertical;
}

.recent-updates {
    margin-left: 4px;
    color: $color-blue-mid;

    strong {
        color: $color-blue-mid;
    }

    &:not(.active) {
        strong {
            font-weight: normal;
        }
    }
}
