.page--home {
    padding: 0;
    background-color: #fff; // #cdcaf9;

    > div:not(.page-header) {
        background-color: #fff;
    }

    .page-header {
        position: relative;
        padding: (20px + 50px) 30px 15px 30px;

        .component--chatbot {
            position: relative;
            z-index: 3;
        }

        .to-content-button {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 10px;
            cursor: pointer;
            text-align: center;
            z-index: 10;

            &:before {
                content: '↓';
                padding: 8px 0 0 0;
                display: block;
                color: $color-blue-mid;
                font-weight: bold;
            }
        }
    }

    &__articles {
        padding-top: 30px;

        @media screen and (min-width: $breakpoint-l-width) {
            padding-top: 20px;
            padding-left: 16px;
            padding-right: 80px;

            h2.title-white {
                // Not planned in screendesign, but only way to create sufficient contrast
                @include text-shadow;
            }
        }

        .link-white {
            display: inline-block;
            margin-top: 5px;
            margin-bottom: 5px;
            color: $color-yellow;
            border-color: $color-yellow;

            @media screen and (min-width: $breakpoint-l-width) {
                margin-top: 45px;
            }
        }

        > * {
            margin: 0 16px;
        }

        @media screen and (max-width: $breakpoint-l-width) {
            .headline-section,
            .title-white {
                color: $color-yellow;
            }
        }

        .component--blog-article-list {
            display: flex;
            margin: 0;
            margin-top: 30px;

            @media screen and (min-width: $breakpoint-l-width) {
                margin-top: 35px;
            }

            @media screen and (max-width: $breakpoint-l-width) {
                overflow-x: scroll;
                padding-bottom: $spacing6;
                padding-right: 15px;
            }

            &::-webkit-scrollbar {
                width: 0; /* Remove scrollbar space */
                background: transparent;
            }

            .blog-card {
                @include box-shadow();
                margin-left: 15px;

                @media screen and (max-width: $breakpoint-l-width) {
                    min-width: 287px;
                }

                @media screen and (min-width: $breakpoint-l-width) {
                    width: 33.33%;
                }

                h2 {
                    @include text-h2;
                    margin-bottom: 12px;
                }

                .blog-card-content p {
                    // CSS hack: Limit preview to three lines
                    // @see https://codepen.io/srekoble/pen/EgmyxV
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

                + .blog-card {
                    margin-top: 0;
                }
            }
        }
    }

    .image-teaser-list {
        width: 100%;
        padding: 16px;
        padding-top: 43px;

        @media screen and (min-width: $breakpoint-l-width) {
            padding-top: 58px;
        }

        .image-teaser {
            position: relative;
            padding: 16px;
            height: 400px;
            margin-top: 16px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            &:first-of-type {
                margin-top: 0;
                background-image: url('../../images/home-image-teaser-green.jpg');
                background-image: image-set(
                    url('../../images/home-image-teaser-green.webp') type('image/webp'),
                    url('../../images/home-image-teaser-green.jpg') type('image/jpeg')
                );
            }

            &.bg-orange {
                background-image: url('../../images/home-image-teaser-orange.jpg');
                background-image: image-set(
                    url('../../images/home-image-teaser-orange.webp') type('image/webp'),
                    url('../../images/home-image-teaser-orange.jpg') type('image/jpeg')
                );
            }

            &.bg-yellow {
                background-image: url('../../images/home-image-teaser-yellow.jpg');
                background-image: image-set(
                    url('../../images/home-image-teaser-yellow.webp') type('image/webp'),
                    url('../../images/home-image-teaser-yellow.jpg') type('image/jpeg')
                );
            }

            .link-white {
                position: absolute;
                bottom: 14px;
                font-weight: bold;
            }
        }
    }

    &-meta {
        background-image: url('../../images/fog-bg-home-mobile.png');
        background-image: image-set(
            url('../../images/fog-bg-home-mobile.webp') type('image/webp'),
            url('../../images/fog-bg-home-mobile.png') type('image/png')
        );
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;

        & > div {
            padding: 0 16px;
        }

        &__about,
        &__other {
            padding-top: 40px !important;
            padding-bottom: 40px !important;

            @media screen and (min-width: $breakpoint-l-width) {
                padding-bottom: 0 !important;
            }
        }

        &__about {
            p {
                max-width: 450px;
            }

            p:not(.label-hashtag-link):not(.p-v) {
                padding-bottom: 13px;
            }

            .p-v {
                padding-top: 13px;
                padding-bottom: 26px;
            }
        }
    }
}
