$box-shadow: 0 0 20px rgba(0, 0, 0, 0.29);
$border-radius: 10px;
$font-color-dark: $color-black;
$bg: #fff;

.klaro {
    .hide {
        display: none;
    }

    .cookie-modal,
    .cookie-notice {
        @media screen and (max-width: $breakpoint-m-width) {
            max-height: 98%;
            overflow-x: scroll;
        }

        .cm-learn-more {
            order: 1;
            transition-duration: 0.2s;
            transition-property: all;
            min-width: 108px;
            display: block;
            margin-bottom: 20px;
            padding: 9px 25px 9px 25px;
            cursor: pointer;
            text-align: center;
            -webkit-appearance: none;
            min-height: 40px;
            line-height: 22px;
            max-width: 100%;
            border-radius: 10px;
            font-size: 18px;
            font-weight: bold;
            background: $color-white;
            color: $color-blue-mid;
            border: 2px solid $color-blue-mid;

            @media screen and (max-width: $breakpoint-m-width) {
                padding: 9px 15px;
            }
        }

        .cm-btn {
            // _button.scss
            border: 1px solid $color-blue-mid;
            color: $color-white;
            background: $color-blue-mid;
            @include transition-all();
            min-width: 108px;
            display: block;
            margin-bottom: $spacing1;
            order: 2;

            padding: 9px 25px 9px 25px;
            cursor: pointer;
            text-align: center;
            -webkit-appearance: none;

            min-height: 40px;
            line-height: $button-line-height;
            max-width: 100%;
            border-radius: 10px 10px 10px 10px;
            font-size: $h2-font-size;
            font-weight: bold;

            &:hover {
                border-color: $color-white;
                color: $color-white;
            }

            // ./_button.scss

            &:disabled {
                opacity: 0.5;
            }

            &.cm-btn-close {
                background: #eee;
                color: #000;
            }

            &.cm-btn-right {
                margin-right: 0;
            }
        }
    }

    .cookie-modal,
    .cookie-notice {
        background: $bg;
        z-index: 999;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        box-shadow: $box-shadow;
        border-radius: $border-radius $border-radius 0 0;

        ul {
            padding: 10px 0 10px 1em !important;

            li {
                padding: 10px 0 !important;
            }
        }

        .cm-list-title {
            font-weight: 700;
            display: inline;
            max-width: calc(100% - 80px);

            @media screen and (max-width: $breakpoint-m-width) {
                display: block;
            }
        }

        .cm-required {
            display: inline;
            padding-right: 80px;
            font-weight: 700;

            @media screen and (max-width: $breakpoint-m-width) {
                display: block;
            }
        }

        .cm-list-description {
            max-width: calc(100% - 80px) !important;
        }

        /*  @media(min-width: 990px){
              box-shadow: $box-shadow;
              border-radius: $border-radius;
              position: fixed;
              bottom: 20px;
              right: 20px;
              max-width: 300px;
          }

          @media(max-width: 989px){
              border: none;
              border-radius: 0;
          }*/
        .cm-modal,
        .cn-body {
            margin: 0 auto;
            bottom: 0;
            width: 100%;
            max-width: $content-max-width;
            padding: 1.5em;

            @media screen and (max-width: $breakpoint-m-width) {
                width: 100%;
            }

            p {
                margin-bottom: 0;
                max-width: $content-text-max-width;
            }

            p.cn-changes {
                text-decoration: underline;
            }

            .cn-learn-more {
                display: inline-block;
            }

            p.cn-ok {
                margin: $spacing6 0 0 0;
                @include flexbox;
                flex-flow: row wrap;
                justify-content: flex-end;
                max-width: 100%;

                @media screen and (min-width: 445px) {
                    justify-content: space-between;
                }
            }

            .cm-btn-accept-all {
                display: none;
            }
        }
    }

    .cookie-notice-hidden {
        display: none !important;
    }

    .cm-footer-buttons {
        @include flexbox;
        justify-content: flex-end;
    }

    // "Switch" input styles
    .switch {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 50px;
        height: 30px;
    }

    .cm-list-input:checked + .cm-list-label .slider {
        background-color: $color-blue-mid;
    }

    .cm-list-input.half-checked:checked + .cm-list-label .slider {
        opacity: 0.6;
        background-color: $color-blue-mid;

        &:before {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }

    .cm-list-input.only-required + .cm-list-label .slider {
        background-color: $color-blue-mid;

        &:before {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }

    .cm-list-input.required:checked + .cm-list-label .slider {
        background-color: $color-blue-mid;
        cursor: not-allowed;
    }

    //.cm-list-input.required:checked ~ div {
    p.purposes {
        // Label "Zweck: Statistken at purpose-item bottom"
        display: none;
    }

    .cm-list-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 50px;
        height: 30px;
    }

    .cm-list-label {
        display: block;
        position: relative;

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            width: 50px;
            display: inline-block;
        }

        .slider:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 5px;
            bottom: 5px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 30px;
        }

        .slider.round:before {
            border-radius: 50%;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
    }

    .cm-list-input:checked + .cm-list-label .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    .cm-apps {
        max-width: $content-text-max-width;
    }
}

@media screen and (max-width: $breakpoint-s-width) {
    .klaro .cm-header .title {
        font-size: 20px;
        line-height: 25px;
    }
}
