.flex-container-list {
    display: flex;
    flex-direction: column;
}

.flex-item-list {
    background-color: $color-white;
    flex-grow: 1;
    flex-basis: content;
    border: 2px solid $color-white;
    border-radius: $radius;
    margin-bottom: 6px;
    min-height: 50px;
    @include box-shadow();
    position: relative;

    > * {
        display: block;
        padding: 14px 50px 14px 25px;
    }

    h2 {
        margin-bottom: 0;
    }

    i {
        position: absolute;
        right: 24px;
        bottom: 30%;
        font-style: normal;

        &:after {
            content: "→";
            display: inline;
            color: $color-blue-mid;
            font-weight: bold;
            font-style: normal;
        }
    }
}
