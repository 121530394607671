.flex-container-switch {
    display: flex;
    flex-flow: row nowrap;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  .flex-item-switch {
    font-size: $h2-font-size;
    color: $color-blue-mid;
    background-color: $color-white;
    line-height: $text-line-height;
    font-weight: bold;
    flex-grow: 1;
    flex-basis: content;
    border: 2px solid $color-blue-mid;
    border-right-width: 0;
    text-align: center;
    height: 40px;
    padding-top: 6px;
    padding-bottom: 2px;
    cursor: pointer;

    &:first-of-type {
      border-radius: $radius-left;
    }

    &:last-of-type {
      border-radius: $radius-right;
      border-right-width: 2px;
    }

    &--active {
      color: $color-white;
      background-color: $color-blue-mid;
    }
  }
