@media screen and (min-width: $breakpoint-l-width) {
    body[data-page='weiteres'],
    body[data-page='flyer-bestellen'],
    body[data-page='kontakt'],
    body[data-page='passwort-vergessen'],
    body[data-page='404'],
    body[data-page^='seiten/'],
    body[data-page^='profil'],
    body[data-page^='chat-thread-add'],
    body[data-page^='einstellungen'],
    body[data-page^='notfallkoffer'],
    body[data-page^='suche?q='] {
        // Trust me, I'm an engineer!
        .titlebar__back-button:after {
            color: $color-black;
        }
    }

    .page:before {
        display: none;
    }

    #root {
        margin: 0 auto;
        padding: 0 30px;
    }

    .header {
        background: none !important;
        position: relative;
        height: 0 !important;

        .titlebar {
            height: 0;
            margin-top: 60px;

            &__title,
            &__toggle-menu {
                display: none;
            }

            &__back-button {
                left: 350px;
            }
        }

        .navbar {
            display: block;
            width: 320px;
            left: 30px;
            top: 60px;
            height: auto;
            padding-top: 0;
            padding-bottom: 15px;
            @include box-shadow();

            &__logo {
                display: block;
                text-align: center;
                padding: 20px 0;

                img {
                    width: 140px;
                }
            }
        }
    }

    .footer {
        width: calc(100% - 220px);
        align-self: flex-end;

        &__menu,
        &__sponsors {
            padding-left: 162px;
        }

        &__menu {
            min-height: 200px;

            > a {
                margin-right: 11%;
            }
        }
    }

    .page {
        width: calc(100% - 220px);
        float: right;
        padding-top: 110px;
        padding-left: 130px;
        padding-right: 0;

        &:not(.page--home) > * {
            max-width: $desktop-page-content-max-width;
        }

        &.page--weiteres,
        &.page--settings,
        &.page--blog-detail .blog-single,
        &.page--faq-question-detail .faq-card-answer,
        &.page--chat-thread-detail .component--chat-article-item {
            margin-top: 15px;
        }

        &.page--home {
            .page-header {
                padding-top: 0;
                padding-bottom: 80px;

                h1 {
                    font-size: 70px;
                    line-height: 80px;
                    max-width: 500px;
                    margin-bottom: 60px;
                    margin-top: -16px;
                }

                .to-content-button {
                    display: none;
                }

                .component--chatbot {
                    width: calc(50% - 30px);
                    min-width: 487px;
                    max-width: initial;
                }
            }

            > div {
                background: transparent;
            }

            .image-teaser-list {
                display: flex;
                justify-content: space-between;
                padding-left: 9px;
                padding-right: 32px;
                margin-bottom: 44px;

                .image-teaser {
                    margin-top: 0;
                    border: 9px solid #fff;
                    width: calc(50% - 5px);

                    h3 {
                        font-size: 35px;
                        line-height: 46px;
                    }
                }
            }

            .page--home-meta {
                display: flex;
                justify-content: space-between;
                padding-bottom: 70px;
                padding-right: 32px;

                > div {
                    width: 50%;
                }
            }
        }
    }

    .component--chatbot-widget-overlay {
        padding-left: 70%;
        padding-bottom: 15px;
        z-index: 9999;
    }
}

@media screen and (min-width: $breakpoint-xl-width) {
    #root {
        padding: 0 5vw;
    }

    .header {
        .titlebar {
            &__back-button {
                left: 23.2vw;
            }
        }

        .navbar {
            width: 21vw;
            left: 6vw;
            max-width: 390px;
        }
    }

    .footer {
        width: 83%;

        &__menu,
        &__sponsors {
            padding-left: calc(8vw + 32px);
        }
    }

    .page {
        width: 83%;
        padding-left: 8vw;

        &.page--home {
            .image-teaser-list {
                padding-right: 64px;

                .image-teaser {
                    margin-top: 0;
                    width: calc(50% - 37px);
                }
            }

            .page--home-meta {
                padding-right: 80px;

                > div {
                    width: calc(50% - 32px);
                }
            }
        }
    }
}
