.p0 { padding: 0 !important; }
.p1 { padding: $spacing1 !important; }
.p2 { padding: $spacing2 !important; }
.p3 { padding: $spacing3 !important; }
.p4 { padding: $spacing4 !important; }
.p5 { padding: $spacing5 !important; }

.py0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.py1 { padding-top: $spacing1 !important; padding-bottom: $spacing1 !important; }
.py2 { padding-top: $spacing2 !important; padding-bottom: $spacing2 !important; }
.py3 { padding-top: $spacing3 !important; padding-bottom: $spacing3 !important; }
.py4 { padding-top: $spacing4 !important; padding-bottom: $spacing4 !important; }
.py5 { padding-top: $spacing5 !important; padding-bottom: $spacing5 !important; }

.px0 { padding-left: 0 !important; padding-right: 0 !important; }
.px1 { padding-left: $spacing1 !important; padding-right: $spacing1 !important; }
.px2 { padding-left: $spacing2 !important; padding-right: $spacing2 !important; }
.px3 { padding-left: $spacing3 !important; padding-right: $spacing3 !important; }
.px4 { padding-left: $spacing4 !important; padding-right: $spacing4 !important; }
.px5 { padding-left: $spacing5 !important; padding-right: $spacing5 !important; }

.pt0 { padding-top: 0 !important; }
.pt1 { padding-top: $spacing1 !important; }
.pt2 { padding-top: $spacing2 !important; }
.pt3 { padding-top: $spacing3 !important; }
.pt4 { padding-top: $spacing4 !important; }
.pt5 { padding-top: $spacing5 !important; }

.pb0 { padding-bottom: 0 !important; }
.pb1 { padding-bottom: $spacing1 !important; }
.pb2 { padding-bottom: $spacing2 !important; }
.pb3 { padding-bottom: $spacing3 !important; }
.pb4 { padding-bottom: $spacing4 !important; }
.pb5 { padding-bottom: $spacing5 !important; }

.pl0 { padding-left: 0 !important; }
.pl1 { padding-left: $spacing1 !important; }
.pl2 { padding-left: $spacing2 !important; }
.pl3 { padding-left: $spacing3 !important; }
.pl4 { padding-left: $spacing4 !important; }
.pl5 { padding-left: $spacing5 !important; }

.pr0 { padding-right: 0 !important; }
.pr1 { padding-right: $spacing1 !important; }
.pr2 { padding-right: $spacing2 !important; }
.pr3 { padding-right: $spacing3 !important; }
.pr4 { padding-right: $spacing4 !important; }
.pr5 { padding-right: $spacing5 !important; }

.m0 { margin: 0 !important; }
.m1 { margin: $spacing1 !important; }
.m2 { margin: $spacing2 !important; }
.m3 { margin: $spacing3 !important; }
.m4 { margin: $spacing4 !important; }

.my0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.my1 { margin-top: $spacing1 !important; margin-bottom: $spacing1 !important; }
.my2 { margin-top: $spacing2 !important; margin-bottom: $spacing2 !important; }
.my3 { margin-top: $spacing3 !important; margin-bottom: $spacing3 !important; }
.my4 { margin-top: $spacing4 !important; margin-bottom: $spacing4 !important; }

.mx0 { margin-left: 0 !important; margin-right: 0 !important; }
.mx1 { margin-left: $spacing1 !important; margin-right: $spacing1 !important; }
.mx2 { margin-left: $spacing2 !important; margin-right: $spacing2 !important; }
.mx3 { margin-left: $spacing3 !important; margin-right: $spacing3 !important; }
.mx4 { margin-left: $spacing4 !important; margin-right: $spacing4 !important; }

.mt0 { margin-top: 0 !important; }
.mt1 { margin-top: $spacing1 !important; }
.mt2 { margin-top: $spacing2 !important; }
.mt3 { margin-top: $spacing3 !important; }
.mt4 { margin-top: $spacing4 !important; }

.mb0 { margin-bottom: 0 !important; }
.mb1 { margin-bottom: $spacing1 !important; }
.mb2 { margin-bottom: $spacing2 !important; }
.mb3 { margin-bottom: $spacing3 !important; }
.mb4 { margin-bottom: $spacing4 !important; }

.ml0 { margin-left: 0 !important; }
.ml1 { margin-left: $spacing1 !important; }
.ml2 { margin-left: $spacing2 !important; }
.ml3 { margin-left: $spacing3 !important; }
.ml4 { margin-left: $spacing4 !important; }

.mr0 { margin-right: 0 !important; }
.mr1 { margin-right: $spacing1 !important; }
.mr2 { margin-right: $spacing2 !important; }
.mr3 { margin-right: $spacing3 !important; }
.mr4 { margin-right: $spacing4 !important; }

.mx-auto { margin-left: auto !important; margin-right: auto !important; }