.component--emergency-kit-list {
    &-item {
        position: relative;
        margin-bottom: 16px;
        min-height: 82px;

        &:first-child {
            .component--emergency-kit-list-item__up {
                display: none;
            }
        }

        &:last-child {
            .component--emergency-kit-list-item__down {
                display: none;
            }
        }

        &__up,
        &__down,
        &__delete {
            width: 40px;
            height: 40px;
            color: #fff;
            position: absolute;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 88;
            text-align: center;
            font-size: 1.5rem;
            cursor: pointer;
            transition: 0.2s background-color;

            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

        &__up:after,
        &__down:after {
            content: '\2192';
            display: inline;
        }

        &__up {
            transform: rotate(-90deg);
            padding-top: 7px;
        }

        &__down {
            bottom: 0;
            transform: rotate(90deg);
            padding-top: 7px;
        }

        &__delete {
            bottom: 0;
            left: 0;
            padding-top: 7px;

            &:after {
                content: '\D7';
                display: inline;
                font-size: 2rem;
            }
        }

        &[data-item-type='text'] {
            background-color: $color-grey-light;

            p {
                padding: 15px;
                font-size: 30px;
                line-height: 35px;
                font-weight: bold;
            }
        }
    }
}

.component--emergency-kit-edit {
    &-add {
        background-color: $color-grey-light;
        padding: 40px 0;
        margin-bottom: 40px;

        .button {
            margin-bottom: 0;
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;
    }
}
