// break points
$breakpoint-s-width: 375px;
$breakpoint-m-width: 768px;
$breakpoint-l-width: 1100px;
$breakpoint-xl-width: 1480px;

// spacing
$spacing1: 20px;
$spacing2: 52px;
$spacing3: 10px;
$spacing4: 33px;
$spacing5: 65px;
$spacing6: 30px;
$spacing7: 45px;
$spacing8: 80px;
$letter-spacing: 1.3px;

// dimensions
$radius-left: 10px 0px 0px 10px;
$radius-right: 0px 10px 10px 0px;
$radius: 10px 10px 10px 10px;

$content-max-width: 1200px;
$content-header-max-width: 1600px;
$content-text-max-width: 730px;
$button-max-width: 540px;

$desktop-page-content-max-width: 768px;

// colors
$color-black: #000000;
$color-white: #ffffff;
$color-blue-mid: #1f1ff5;
$color-blue-dark: #1111c3;
$color-pink: #ff00e2;
$color-orange: #ff821a;
$color-yellow: #d3d220;
$color-green: #18dd7e;
$color-green-light: #d0f8e5;
$color-grey-light: #e5e5e5;
$color-grey-dark: #8a8a8a;

// font-size and line-height
$title-font-size: 40px;
$title-line-height: 50px;
$h1-font-size: 30px;
$h1-line-height: 35px;
$h2-font-size: 18px;
$h2-line-height: 22px;
$h3-font-size: 16px;
$h3-line-height: 20px;

$meta-text-font-size: 14px;
$meta-text-line-height: 20px;
$label-line-height: 14px;
$label-line-height-white: 18px;
$button-line-height: 22px;
$button-close-line-height: 42 px;
$radiobutton-line-height: 24px;

$text-font-size: 16px;
$text-line-height: 22px;
$text-font-size-m: 16px;
$text-line-height-m: 20px;

// z-index
$z-index-menu: 20;
$z-index-notification: 30;
