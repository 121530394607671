.footer {
    width: 100%;
    height: auto;
    position: relative;
    background-color: $color-white;

    &__menu {
        background: $color-blue-mid url('../../images/fideo-schleife.svg') no-repeat -32px 110px;
        background-size: 110% auto;
        padding: 40px 32px;
        position: relative;

        * {
            color: $color-white;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                line-height: 1;

                a {
                    font-size: $meta-text-font-size;
                    line-height: 1;
                }
            }

            li + li {
                margin-top: 20px;
            }
        }
    }

    &__sponsors {
        padding: 40px 32px;

        p {
            margin-bottom: 18px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            li {
                width: 40%;
                height: auto;
            }
        }
    }

    &__logo {
        position: absolute;
        width: 120px;
        height: 55px;
        background: transparent url('../../images/fideo-logo.svg') no-repeat center center;
        background-size: 100% auto;
        top: 40px;
        right: 32px;
    }

    .logo-barmer {
        display: block;
        font-size: 0;
        line-height: 1;
        width: 120px;
        height: 19px;
        background: transparent url('../../images/barmer-logo.svg') no-repeat center center;
        background-size: 100% auto;
    }

    .logo-ddh {
        display: block;
        font-size: 0;
        line-height: 1;
        width: 124px;
        height: 90px;
        background: transparent url('../../images/ddh-logo-neu.png') no-repeat center center;
        background-size: 100% auto;
    }
}

.to-top-button {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 32px;
    border: 2px solid $color-white;
    border-radius: 10px 10px 10px 10px;
    font-size: 18px;
    line-height: 1;
    color: $color-white;
    font-weight: bold;
    text-align: center;
    background-color: transparent;
    cursor: pointer;

    &:before {
        content: '↑';
        padding: 8px 0 0 0;
        display: block;
    }

    &:hover {
        background-color: $color-white;
        color: $color-blue-mid;
    }
}
