.page--search {
    .flex-container-switch {
        margin-top: 9px;
    }
}

.search-result-list {
    margin-top: 32px;

    .highlight {
        font-weight: bold;
        color: $color-blue-mid !important;
        border: 2px solid $color-blue-mid;
        border-radius: 4px;
        padding-left: 3px;
        padding-right: 3px;
    }

    p .highlight {
        margin-left: 1px;
        margin-right: 1px;
    }

    &-item {
        padding-bottom: 16px;

        &:first-of-type {
            padding-top: 16px;
        }

        > a,
        > .chat-card {
            @include box-shadow();
        }

        .chat-card-user {
            margin-top: 0;
        }

        .component--chat-thread-list-item > .chat-card-user {
            margin-left: 15px;
            margin-bottom: 16px;
        }

        &--blog {
            background-color: $color-yellow;
        }

        &--faq {
            background-color: $color-orange;
        }

        &--chat {
            background-color: $color-green;

            .component--chat-thread-list-item {
                margin-bottom: 0;
            }
        }
    }
}
