button, .button {
    border: 1px solid $color-blue-mid;
    color: $color-white;
    background: $color-blue-mid;
    @include transition-all();
    min-width: 108px;
    display: block;
    margin-bottom: $spacing1;

    padding: 9px 25px 9px 25px;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;

    min-height: 40px;
    line-height: $button-line-height;
    max-width: 100%;
    border-radius: 10px 10px 10px 10px;
    font-size: $h2-font-size;
    font-weight: bold;

    &:hover {
        border-color: $color-white;
        color: $color-white;
    }

    &.button-submit {
        min-width: 130px;
        min-height: 40px;

        &:after {
            content: "→";
            display: inline;
            font-style: normal;
            font-weight: bold;
            margin-left: 5px;
        }

        + .button, button {
            margin-top: 17px;
        }

        + .chat-input {
            margin-top: 24px;
        }
    }

    &.button-secondary {
        background: $color-white;
        color: $color-blue-mid;
        border-width: 2px;

        &:hover {
            background: $color-blue-mid;
            color: $color-white;
        }
    }

    &.button-hollow {
        background: transparent;
        color: $color-white;
        border: 2px solid $color-white;

        &:hover {
            background: rgba(255,255,255,0.4);
        }
    }

    &:disabled,
    &[disabled],
    &.button-disabled {
        background: $color-grey-dark;
        border-color: transparent;
    }

    &.button-send {
        min-width: 62px;
        min-height: 40px;
        padding: 0;

        &:after {
            content: "→";
            display: inline;
            font-weight: bold;
            font-style: normal;
        }
    }

    &.button-close-blue {
        min-width: 42px;
        min-height: 43px;
        font-size: 34px;
        font-weight: normal;
        line-height: 1;
        text-align: center;
        padding: 0 9px 3px 9px;

        &:after {
            content: "×";
            display: inline;
        }
    }

    &.button-close-white {
        min-width: 42px;
        min-height: 43px;
        font-size: 34px;
        font-weight: normal;
        line-height: 1;
        background-color: $color-white;
        color: $color-blue-mid;
        border-width: 2px;
        padding: 0 9px 3px 9px;
        text-align: center;

        &:after {
            content: "×";
            display: inline;
        }
    }

    &.button-center {
        margin: 0 auto;
        margin-bottom: $spacing1;
    }

    @media screen and (min-width: $breakpoint-m-width) {
        .button {
            height: 50px;
            line-height: 50px;
            background: transparent;
            max-width: $button-max-width;
        }
    }
}
