@font-face {
    font-family: 'Graphit';
    src: url('../../../../styleguide/src/fonts/Graphit-Regular/graphit-regular.woff2')
            format('woff2'),
        url('../../../../styleguide/src/fonts/Graphit-Regular/graphit-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphit';
    src: url('../../../../styleguide/src/fonts/Graphit-Bold/graphit-bold.woff2') format('woff2'),
        url('../../../../styleguide/src/fonts/Graphit-Bold/graphit-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
