@mixin transition-all($duration: 0.2s) {
    -webkit-transition-duration: $duration;
    -moz-transition-duration: $duration;
    -o-transition-duration: $duration;
    transition-duration: $duration;

    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
}

@mixin transition($property, $duration: 0.2s) {
    -webkit-transition-duration: $duration;
    -moz-transition-duration: $duration;
    -o-transition-duration: $duration;
    transition-duration: $duration;

    -webkit-transition-property: $property;
    -moz-transition-property: $property;
    -o-transition-property: $property;
    transition-property: $property;
}

@mixin border-radius($radius: 3px) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow() {
    box-shadow: 0 13px 20px rgba(0, 0, 0, 0.29);
}

@mixin text-shadow() {
    text-shadow: 0 13px 20px rgba(0, 0, 0, 0.29);
}

@mixin text-h1() {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    font-weight: bold;
    margin-bottom: $spacing1;
    hyphens: auto;

    * {
        font-size: $h1-font-size;
        line-height: $h1-line-height;
        font-weight: bold;
    }
}

@mixin text-h2() {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-weight: bold;
    margin-bottom: $spacing1;

    * {
        font-size: $h2-font-size;
        line-height: $h2-line-height;
        font-weight: bold;
    }
}

@mixin text-h3() {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: bold;
    margin-bottom: $spacing1;

    * {
        font-size: $h3-font-size;
        line-height: $h3-line-height;
        font-weight: bold;
    }
}
