.radio-wrapper {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    height: 21px;
    margin-top: 10px;
    margin-bottom: 10px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        height: 21px;
        width: 21px;
        background-color: $color-white;
        border: 2px solid $color-blue-mid;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        border-radius: 50%;
        left: 3px;
        top: 3px;
        width: 11px;
        height: 11px;
        background: $color-blue-mid;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .radio-text {
        display: block;
        font-size: $h2-font-size;
        line-height: $radiobutton-line-height;
        width: 100%;
        padding-left: 31px;
    }
}

@media screen and (min-width: $breakpoint-m-width) {
    .radio-wrapper {
        height: 30px;
        margin-bottom: 15px;

        .checkmark {
            height: 31px;
            width: 31px;
        }

        .checkmark:after {
            left: 5px;
            top: 5px;
            width: 17px;
            height: 17px;
        }

        .radio-text {
            padding-left: 40px;
            padding-top: 3px;
        }
    }
}
