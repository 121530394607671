@keyframes expand-bounce {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    20% {
        transform: translateY(-5px);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@mixin animation-chatbot() {
    animation-fill-mode: forwards;
    animation-name: expand-bounce;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

$page-transition-duration: 400ms;

@media screen and (max-width: $breakpoint-l-width) {
    // Not used right now (page transition disabled)
    .page-enter {
        left: 100%;
        z-index: 1;
        opacity: 0.1;
    }

    .page-enter-active {
        left: 0;
        z-index: 1;
        opacity: 1;
        transition: all $page-transition-duration;
    }

    .page-exit {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .page-exit-active {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all $page-transition-duration;
    }
}
