.checkbox-wrapper {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    min-height: 35px;
    margin-top: 6px;
    vertical-align: top;
    margin-bottom: 10px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid $color-blue-mid;
        display: inline-block;
        position: absolute;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;

        border: solid $color-blue-mid;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);

        left: 6px;
        top: 3px;
        width: 6px;
        height: 10px;

    }

    input:checked ~ .checkmark {

    }

    input:checked ~ .checkmark:after {
        display: block;

    }

    .checkbox-text {
        display: block;
        width: 100%;
        padding-left: 30px;
        padding-top: 0;
        margin-top: 2px;
        font-size: $h2-font-size;
        line-height: $radiobutton-line-height;

        * {
            font-size: $h2-font-size;
            line-height: $radiobutton-line-height;
        }

        a {
            text-decoration: underline;
        }
    }
}

@media screen and (min-width: $breakpoint-m-width) {
    .checkbox-wrapper {
        height: 50px;
        margin-bottom: 30px;

        .checkmark {
            height: 30px;
            width: 30px;
        }

        .checkmark:after {
            left: 10px;
            top: 4px;
            width: 9px;
            height: 15px;
        }

        .checkbox-text {
            padding-left: 40px;
            padding-top: 4px;
        }
    }
}
