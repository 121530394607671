.dropdown {
    position: relative;

    &__list {
        @include box-shadow;
        list-style: none;
        background-color: $color-white;
        border-radius: 0 0 10px 10px;
        padding: 20px 20px 0;
        position: absolute;
        width: 100%;
        margin-top: 3px;
        //transform: translateY(-100%);
        z-index: 998;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            height: 40px;

            .meta-text {
                margin: 0;
            }
        }
    }
}
